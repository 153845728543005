<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card :loading="loading">
      <app-page-header
        icon="mdi-alarm-check"
        :title="$t('global.greeting', [fullName])"
        @close="onClose"
        :disabled="loading"
      />
      <v-card-text class="pt-4">
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-row v-if="terminal.clockInOutAllowed && !clockInOutDisabled">
              <v-col cols="6">
                <v-card flat class="green-border" :disabled="loading" @click="onClickClockRecord(EventType.CLOCK_IN)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.clock_in") }}</span>
                      <v-icon color="success" size="36">tw-in</v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat class="red-border" :disabled="loading" @click="onClickClockRecord(EventType.CLOCK_OUT)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.clock_out") }}</span>
                      <v-icon color="primary" size="36">tw-out</v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-card
                  flat
                  class="green-border"
                  :disabled="loading"
                  @click="onClickClockRecord(EventType.HOME_CLOCK_IN)"
                >
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.home_duty_in") }}</span>
                       <v-icon color="success" style="height:36px;width:36px" :style="{'background-image': 'url(' + require('@/assets/icons/HOME_CLOCK_IN.svg') + ')'}"></v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  flat
                  class="red-border"
                  :disabled="loading"
                  @click="onClickClockRecord(EventType.HOME_CLOCK_OUT)"
                >
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.home_duty_out") }}</span>
                      <v-icon color="primary" style="height:36px;width:36px" :style="{'background-image': 'url(' + require('@/assets/icons/HOME_CLOCK_OUT.svg') + ')'}"></v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
               <v-row>
              <v-col cols="6">
                <v-card flat class="green-border" :disabled="loading" @click="onClickClockRecord(EventType.TRAINING_IN)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.training_in") }}</span>
                      <v-icon color="success" style="height:36px;width:36px" :style="{'background-image': 'url(' + require('@/assets/icons/TRAINING_IN.svg') + ')'}"></v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6">
                <v-card flat class="red-border" :disabled="loading" @click="onClickClockRecord(EventType.TRAINING_OUT)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.training_out") }}</span>
                      
                      <v-icon color="primary" style="height:36px;width:36px" :style="{'background-image': 'url(' + require('@/assets/icons/TRAINING_OUT.svg') + ')'}"></v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="terminal.dutyInOutAllowed">
              <v-col cols="6">
                <v-card flat class="green-border" :disabled="loading" @click="onClickClockRecord(EventType.DUTY_IN)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.duty_in") }}</span>
                      <v-icon color="success" size="36">tw-duty-in</v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat class="red-border" :disabled="loading" @click="onClickClockRecord(EventType.DUTY_OUT)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.duty_out") }}</span>
                      <v-icon color="primary" size="36">tw-duty-out</v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="terminal.breakInOutAllowed && !breakInOutDisabled">
              <v-col cols="6">
                <v-card flat class="green-border" :disabled="loading" @click="onClickClockRecord(EventType.BREAK_IN)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.break_in") }}</span>
                      <v-icon color="success" size="36">tw-break-in</v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat class="red-border" :disabled="loading" @click="onClickClockRecord(EventType.BREAK_OUT)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.break_out") }}</span>
                      <v-icon color="primary" size="36">tw-break-out</v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="terminal.lunchInOutAllowed">
              <v-col cols="6">
                <v-card flat class="green-border" :disabled="loading" @click="onClickClockRecord(EventType.LUNCH_IN)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.lunch_in") }}</span>
                      <v-icon color="success" size="36">tw-lunch-in</v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat class="red-border" :disabled="loading" @click="onClickClockRecord(EventType.LUNCH_OUT)">
                  <v-card-text>
                    <v-layout justify-space-between align-center>
                      <span>{{ $t("time.event.lunch_out") }}</span>
                      <v-icon color="primary" size="36">tw-lunch-out</v-icon>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="2">
            <v-layout>
              <v-flex xs12>
                <validation-observer ref="description">
                  <validation-provider name="description" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model.trim="clockRecord.description"
                      :error-messages="errors"
                      :label="$t('global.description')"
                      color="tertiary"
                      clearable
                      class="mb-3"
                    />
                  </validation-provider>
                </validation-observer>
              </v-flex>
            </v-layout>
            <v-layout justify-space-between>
              <v-btn depressed @click="onClickBack">{{ $t("buttons.back") }}</v-btn>
              <v-btn color="primary" depressed @click="onSaveClockRecord">{{ $t("buttons.save") }}</v-btn>
            </v-layout>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description ClockInClockOut Component Logic
   * @date 17.08.2020
   */

  import ClockRecordModel from "./model/ClockRecordModel";
  import AppPageHeader from "../../layouts/app-page-header";
  import EventType from "../../enumeration/EventType";
  import { mapGetters } from "vuex";
  export default {
    name: "ClockInClockOut",
    components: { AppPageHeader },
    data: () => ({
      EventType,
      loading: false,
      dialog: true,
      step: 1,
      terminal: {
        clockInOutAllowed: true,
        clockInOutDescriptionRequired: false,
        dutyInOutAllowed: true,
        dutyInOutDescriptionRequired: false,
        breakInOutAllowed: true,
        breakInOutDescriptionRequired: false,
        lunchInOutAllowed: true,
        lunchInOutDescriptionRequired: false
      },
      clockRecord: new ClockRecordModel()
    }),
    computed: {
      ...mapGetters({
        fullName: "auth/fullName",
        routeDisabled: "auth/routeDisabled"
      }),
      clockInOutDisabled() {
        return this.routeDisabled(this.$enums.MOBILE_SETTINGS.CLOCK_IN_OUT_DISABLED);
      },
      breakInOutDisabled() {
        return this.routeDisabled(this.$enums.MOBILE_SETTINGS.BREAK_IN_OUT_DISABLED);
      }
    },
    methods: {
      onClickClockRecord(eventType) {
        if (eventType && !this.loading) {
          this.clockRecord.eventType = eventType;

          if (
            (eventType === EventType.CLOCK_IN || eventType === EventType.CLOCK_OUT) &&
            this.terminal.clockInOutDescriptionRequired
          ) {
            this.step = 2;
          } else if (
            (eventType === EventType.DUTY_IN || eventType === EventType.DUTY_OUT) &&
            this.terminal.dutyInOutDescriptionRequired
          ) {
            this.step = 2;
          } else if (
            (eventType === EventType.BREAK_IN || eventType === EventType.BREAK_OUT) &&
            this.terminal.breakInOutDescriptionRequired
          ) {
            this.step = 2;
          } else if (
            (eventType === EventType.LUNCH_IN || eventType === EventType.LUNCH_OUT) &&
            this.terminal.lunchInOutDescriptionRequired
          ) {
            this.step = 2;
          }
          // else if (eventType === EventType.HOME_CLOCK_IN || eventType === EventType.HOME_CLOCK_OUT) {}
          else {
            this.validateAndSave();
          }
        }
      },
      validateAndSave() {
        this.loading = true;
        this.$api.clockRecordService
          .save(this.clockRecord)
          .then(({ data }) => {
            if (!data.error) {
              this.dialog = false;
              this.$helpers.showNotification("registration_successful", "success");
              this.$eventBus.$emit("add:clock-records", data.data);
              this.$emit("close");
            } else {
              this.$helpers.showNotification("registration_failed");
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      },
      onClose() {
        this.dialog = false;
        this.$emit("close");
      },
      async fetchItems() {
        this.loading = true;
        await this.$store
          .dispatch("auth/FETCH_TERMINAL")
          .then(data => {
            this.terminal = data;
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      },
      onClickBack() {
        this.$refs.description.reset();
        this.clockRecord.eventType = null;
        this.clockRecord.description = null;
        this.step = 1;
      },
      onSaveClockRecord() {
        this.$refs.description.validate().then(valid => {
          if (valid) {
            this.validateAndSave();
          }
        });
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped>
  .green-border {
    border: 2px solid var(--v-success-base);
  }

  .red-border {
    border: 2px solid var(--v-primary-base);
  }
</style>
