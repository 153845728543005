export default Object.freeze({
  CLOCK_IN: "CLOCK_IN",
  CLOCK_OUT: "CLOCK_OUT",
  HOME_CLOCK_IN: "HOME_CLOCK_IN",
  HOME_CLOCK_OUT: "HOME_CLOCK_OUT",
  DUTY_IN: "DUTY_IN",
  DUTY_OUT: "DUTY_OUT",
  LUNCH_IN: "LUNCH_IN",
  LUNCH_OUT: "LUNCH_OUT",
  TRAINING_IN:"TRAINING_IN",
  TRAINING_OUT:"TRAINING_OUT",
  BREAK_IN: "BREAK_IN",
  BREAK_OUT: "BREAK_OUT",
  UNKNOWN: "UNKNOWN"
});
